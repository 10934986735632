import React from "react";
import Head from 'next/head'
import { useTranslation } from "@/components/useTranslation";
import Hero from '@/components/home/hero'
import RatedCustomers from "@/components/home/rated-customers";
import Content1 from "@/components/home/content/content-1";
import Price from "@/components/price/price";
import Content2 from "@/components/home/content/content-2";
// import Content3 from "@/components/home/content/content-3";
// import DiscountModal from "@/components/static/discountModal"
import Services from "@/components/static/services";
import Platform from "@/components/home/platform";
// import { useState, useEffect } from "react";
// import Pumpkin from "@/components/home/pumpkin";

export default function Home() {

  // const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowModal(true);
  //   }, 10000);
  //   return () => clearTimeout(timer);
  // }, []);

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // }
  const t = useTranslation();


  return (
    <>
      <Head>
        <title> Funfsterne </title>
      </Head>

      <Hero />
      <div className="" style={{
        backgroundImage: "url('/images/global/background/gradient2.png')",
        backgroundRepeat: "repeat",
        backgroundSize: "100%",
      }}>
        <RatedCustomers />
        <Content1 />
        <Price />
        <Content2 />
        <Services />
        <Platform />
        {/* <DiscountModal show={showModal} onClose={handleCloseModal} /> */}
      </div>
    </>

  )
}





